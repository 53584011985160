<template>
  <div v-loading="loading" class="scan">
    <div class="operate">
      <div class="title">
        扫描点列表
        <span v-if="selectedRows.length" class="choose"
          >已选择<span class="num">{{ selectedRows.length }}</span
          >条数据</span
        >
      </div>
      <div class="btns">
        <a-space>
          学段：
          <a-select
            v-model="search.level"
            allow-clear
            not-found-content="暂无数据"
            placeholder="所有学段"
            style="width: 220px"
            @change="inputChange"
          >
            <a-select-option
              v-for="(item, index) in levelOptions"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-input
            v-model="search.keyword"
            placeholder="请输入关键字搜索"
            style="width: 180px"
            @input="inputChange"
          >
            <a-icon
              slot="suffix"
              type="search"
              style="color: rgba(0, 0, 0, 0.45)"
            />
          </a-input>
          <a-button type="primary" @click="addScan"
            ><a-icon type="plus-circle" />添加</a-button
          >
          <a-button type="primary" @click="importExcel">
            <img
              src="@/assets/uExam/icon_import.png"
              style="width: 12px; margin-right: 6px"
              alt=""
            />导入</a-button
          >
          <a-button type="primary" @click="exportFile"
            ><img
              src="@/assets/uExam/icon_export.png"
              style="width: 12px; margin-right: 6px"
              alt=""
            />导出</a-button
          >
          <a-button type="danger" @click="batch"
            ><a-icon type="delete" />批量删除</a-button
          >
        </a-space>
      </div>
    </div>
    <a-table
      :columns="columns"
      :row-selection="rowSelection"
      :data-source="tableData"
      bordered
      :locale="locale"
      :pagination="{
        total: total,
        pageSize: search.size,
        current: search.current,
        showSizeChanger: true,
        showTotal: (total) => `共 ${total}条`,
      }"
      @change="tablePaginationChange"
    >
      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="edit(record)">修改</a-button>
        <a-button type="link" @click="deleteItem(record)">删除</a-button>
      </template>
    </a-table>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :width="width4Title[title]"
      centered
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <template v-if="title === '添加扫描点' || title === '修改扫描点'">
        <div class="tips">
          <div>1、扫描点名称中只能含有汉字，字母，数字或者下划线；</div>
          <div>2、扫描点名称不能超过20个字；</div>
        </div>
        <a-form-model
          ref="form"
          :rules="rules"
          :model="form"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-row>
            <a-col :span="8">
              <a-form-model-item label="适用学段" prop="level">
                <a-select
                  v-model="form.level"
                  placeholder="所有学段"
                  allow-clear
                  @change="transferFilterKey = Math.random()"
                >
                  <a-select-option
                    v-for="(item, index) in levelOptions"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="16">
              <a-form-model-item
                label="扫描点名称"
                :label-col="{ span: 6 }"
                prop="scanPointName"
              >
                <a-input
                  v-model="form.scanPointName"
                  placeholder="请输入扫描点名称"
                ></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item
            label="扫描点学校"
            :label-col="{ span: 24 }"
            :wrapper-col="{ span: 24 }"
          >
            <transferFilter
              :id="form.id"
              :key="transferFilterKey"
              :school-ids="form.schoolIds"
              :level="form.level"
              @change="transferFilterChange"
            />
          </a-form-model-item>
        </a-form-model>
      </template>
      <template v-if="title === '批量删除'">
        <div class="text">
          此操作将永久删除 <span>{{ selectedRows.length }}</span> 条扫描点信息！
        </div>
        <el-input
          v-model="notInput"
          style="width: 0; height: 0; overflow: hidden"
          placeholder="请输入内容"
        ></el-input>
        <div class="text">
          验证登录密码以继续
          <a-input-password
            v-model="password"
            style="width: 180px; margin-left: 20px"
            autocomplete="new-password"
          ></a-input-password>
        </div>
        <div style="text-align: center">此操作不可撤销</div>
      </template>
      <template v-if="['添加管理员', '编辑管理员'].includes(title)">
        <transfer
          :key="'transfer' + transferFilterKey"
          :value="checkUserList"
          :data-source="scanUserList"
          @change="transferChange"
        />
      </template>
      <template v-if="title === '导入扫描点'">
        <ImportUpload
          :key="uploadKey"
          :file-model="{
            name: '扫描点导入模板.xls',
            url: 'https://static.wtjy.com/cms/template/%E5%AF%BC%E5%85%A5%E6%89%AB%E6%8F%8F%E7%82%B9%E6%A8%A1%E6%9D%BF%20(%E6%96%B0).xls',
          }"
          @change="uploadChange"
        >
          <template slot="tips">
            <div class="tips">
              <div class="tips-title">【提示】</div>
              <div>
                1、扫描点名称只能含有汉字，字母，数字或下划线；名称不能超过20个字；
              </div>
              <div>2、同一学段下，扫描点名称不允许重复；</div>
              <div>3、学校名必须与导入系统中的名称一致；</div>
              <div>4、管理员必须与用户管理中的名称一致；</div>
              <div>5、同一学段下，各扫描点学校名称不允许重复；</div>
              <div>6、同一学段下，管理员扫描点名称不允许重复；</div>
              <div>
                7、导入文件中，只要存在一条数据不合法，则所有数据都无法导入。
              </div>
            </div>
          </template>
        </ImportUpload>
      </template>
      <template v-if="title === '导出'">
        <a-form-model
          ref="form"
          :rules="rules"
          :model="form"
          :label-col="{ span: 10 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-form-model-item label="选择需要导出的学段" prop="level">
            <a-select
              v-model="form.level"
              allow-clear
              not-found-content="暂无数据"
              placeholder="所有学段"
              style="width: 220px"
              @change="inputChange"
            >
              <a-select-option
                v-for="(item, index) in levelOptions"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="选择需要导出的内容" prop="type">
            <a-select
              v-model="form.type"
              allow-clear
              not-found-content="暂无数据"
              placeholder="选择"
              style="width: 220px"
              @change="inputChange"
            >
              <a-select-option value="0"> 扫描点信息</a-select-option>
              <a-select-option value="1"> 管理员账号</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </template>
    </a-modal>
  </div>
</template>

<script>
import noData from "@/components/noData.vue";
import { levelOptions } from "@/core/util/constdata";
import transfer from "../components/transfer.vue";
import {
  scanpointPage,
  addScanPoint,
  delScanPoint,
  scanPointUserList,
  exportScanPoint,
  importScanPoint,
} from "@/core/api/newOther/union";
import { encryption } from "@/core/util/util";
import { getStore } from "@/core/util/store";
import ImportUpload from "@/components/ImportUpload.vue";

import transferFilter from "../components/transferFilter.vue";
export default {
  name: "Scan",
  components: {
    transfer,
    transferFilter,
    ImportUpload,
  },
  data() {
    const validateName = (rule, value, callback) => {
      var pattern = new RegExp(
        "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      );
      if (!value) {
        callback(new Error("请输入扫描点名称"));
      } else if (pattern.test(value)) {
        callback(new Error("扫描点名称只能含有汉字，字母，数字或下划线"));
      } else if (value.length > 20) {
        callback(new Error("扫描点名称不能超过20个字"));
      } else {
        callback();
      }
    };
    return {
      input: undefined,
      search: {
        size: 20,
        current: 1,
      },
      rules: {
        level: [{ required: true, message: "请选择学段", trigger: "change" }],
        type: [
          {
            required: true,
            message: "请选择需要导出的内容",
            trigger: "change",
          },
        ],
        scanPointName: [
          { required: true, validator: validateName, trigger: "change" },
        ],
      },
      confirmLoading: false,
      checkUserList: [],
      width4Title: {
        添加管理员: "45%",
        编辑管理员: "45%",
        批量删除: "390px",
        添加扫描点: "45%",
        修改扫描点: "45%",
        导入扫描点: "35%",
        导出: "450px",
      },
      title: "",
      visible: false,
      total: 1996,
      password: null,
      notInput: "",
      columns: [
        {
          title: "学段",
          dataIndex: "level",
          align: "center",
          width: 75,
          customRender: (text, record) => {
            let levelList = levelOptions();
            let level = levelList.find((item) => record.level == item.value);
            if (level) return level.label;
            return "";
          },
        },
        {
          title: "扫描点名称",
          dataIndex: "name",
          align: "center",
          width: 150,
        },
        {
          title: "扫描点学校",
          dataIndex: "schools",
          align: "center",
          width: 400,
          customRender: (text, record) => {
            if (record.schoolNames) {
              let schoolNames = record.schoolNames.split(",");
              return (
                <div class="shcools">
                  {...schoolNames.map((item) => (
                    <a-tooltip>
                      <template slot="title">{item}</template>
                      <div class="tag">{item}</div>
                    </a-tooltip>
                  ))}
                </div>
              );
            }
            return "";
          },
        },
        {
          title: "管理人员",
          dataIndex: "userList",
          align: "center",
          width: 500,
          scopedSlots: { customRender: "userList" },
          customRender: (text, record) => {
            if (record.usernames) {
              const reg = /\((.*?)\)/g;
              let userNames = record.usernames.split(",");

              let nameList = userNames.map((item) => {
                let str = item.split("(")[0];
                let name = item.match(reg)[0];

                name = name.replace("(", "");
                name = name.replace(")", "");
                return {
                  name: name,
                  username: str,
                };
              });

              return (
                <div class="shcools">
                  {...nameList.map((item) => (
                    <a-tooltip>
                      <template slot="title">
                        {item.name + "(" + item.username + ")"}
                      </template>
                      <div class="user-tag">
                        <div class="name">{item.name}</div>
                        <div class="username">{item.username}</div>
                      </div>
                    </a-tooltip>
                  ))}
                  <div class="addBtn" vOn:click={() => this.addUser(record)}>
                    <a-icon type="form" />
                  </div>
                </div>
              );
            }
            return (
              <div class="addBtn" vOn:click={() => this.addUser(record)}>
                <a-icon type="plus" />
              </div>
            );
          },
        },
        {
          title: "操作",
          dataIndex: "action",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "action" },
        },
      ],
      form: {
        scanPointName: "",
      },
      loading: false,
      tableData: [],
      selectedRows: [],
      transferFilterKey: Math.random(),
      scanUserList: [],
      level: undefined,
      levelOptions: levelOptions(),
      fileList: null,
      locale: {
        emptyText: <noData message="暂无扫描点信息，请添加扫描点信息" />,
      },
    };
  },
  computed: {
    rowSelection() {
      return {
        onSelect: (record, selected, selectedRows) => {
          this.selectedRows = selectedRows;
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectedRows = selectedRows;
        },
      };
    },
  },
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    uploadChange({ fileList }) {
      this.fileList = fileList[0];
    },
    async exportFile() {
      this.title = "导出";
      this.visible = true;
    },
    transferChange(list) {
      this.checkUserList = list;
    },
    deleteItem(record) {
      this.$confirm("当前操作将删除该扫描点，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await delScanPoint({
            scanPointIds: record.id,
          });
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          this.getData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    edit(record) {
      this.title = "修改扫描点";
      let schoolIds = record.schoolIds && record.schoolIds.split(",");
      this.form = {
        ...record,
        level: record.level && record.level.toString(),
        scanPointName: record.name,
        schoolIds: schoolIds.map(Number),
      };
      this.transferFilterKey = Math.random();
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    transferFilterChange(val) {
      this.form.schoolIds = val.map((item) => item.schoolId);
    },
    async getData() {
      this.loading = true;
      try {
        this.loading = false;
        const res = await scanpointPage({ ...this.search });
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    inputChange() {
      this.search.current = 1;
      this.getData();
    },
    importExcel() {
      this.visible = true;
      this.title = "导入扫描点";
    },
    batch() {
      this.visible = true;
      this.title = "批量删除";
    },
    handleCancel() {
      this.visible = false;
    },
    handleOk() {
      const funObj = {
        添加扫描点: this.add,
        修改扫描点: this.add,
        添加管理员: this.addUserSubmit,
        编辑管理员: this.addUserSubmit,
        批量删除: this.batchDeleteSubmit,
        导出: this.exportSubmit,
        导入扫描点: this.importScanPointSubmit,
      };
      funObj[this.title]();
    },
    async importScanPointSubmit() {
      if (this.fileList) {
        let data = new FormData();
        data.append("file", this.fileList.originFileObj);
        try {
          this.confirmLoading = true;
          await importScanPoint(data);
          this.visible = false;
          this.confirmLoading = false;
          this.$message({
            type: "success",
            message: "提交成功",
            showClose: true,
          });
          this.getData();
        } catch {
          this.confirmLoading = false;
          // console.log(e);
        }
      } else {
        this.$message({
          type: "waring",
          message: "请选择需要导入的文件",
          showClose: true,
        });
      }
    },
    async exportSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.confirmLoading = true;
          try {
            const res = await exportScanPoint({ ...this.form });
            const { href } = this.$router.resolve({
              path: "/downFile",
              query: {
                url: res.data.data.url,
                name: res.data.data.name,
                type: 1,
              },
            });
            let timer = setTimeout(() => {
              window.open(href, "_blank");
              clearTimeout(timer);
            }, 0);
            this.visible = false;
            this.confirmLoading = false;
          } catch {
            this.confirmLoading = false;
          }
        }
      });
    },
    async batchDeleteSubmit() {
      if (this.selectedRows.length) {
        this.confirmLoading = true;
        try {
          const user = encryption({
            data: {
              randomStr: "blockPuzzle",
              code: "xxx",
              password: this.password,
            },
            key: "pigxpigxpigxpigx",
            param: ["password"],
          });
          await delScanPoint({
            scanPointIds: this.selectedRows.map((item) => item.id).join(","),
            ifBatch: true,
            password: user.password,
          });
          this.$message({
            type: "success",
            message: "提交成功",
            showClose: true,
          });
          this.visible = false;
          this.search.current = 1;
          this.getData();
          this.password = undefined;
          this.confirmLoading = false;
          this.selectedRows = [];
        } catch {
          this.confirmLoading = false;
        }
      } else {
        this.$message({
          type: "warning",
          message: "请选择需要删除的扫描点",
          showClose: true,
        });
      }
    },
    async addUserSubmit() {
      this.confirmLoading = true;
      try {
        await addScanPoint({
          ...this.form,
          scanPointIds: this.form.id,
          unionUserIds: this.checkUserList.map((item) => item.id).join(","),
          scanPointName: this.form.scanPointName,
          type: 1,
          schoolUnionId: Number(getStore({ name: "school_id" })),
        });
        this.confirmLoading = false;
        this.getData();
        this.$message({
          type: "success",
          message: "提交成功",
          showClose: true,
        });
        this.visible = false;
        this.form = {};
      } catch {
        this.confirmLoading = false;
      }
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.confirmLoading = true;
          try {
            this.$delete(this.form, "name");
            await addScanPoint({
              ...this.form,
              schoolIds: this.form.schoolIds && this.form.schoolIds.join(","),
              scanPointIds: this.form.id,
              type: 0,
              schoolUnionId: Number(getStore({ name: "school_id" })),
            });
            this.confirmLoading = false;
            this.getData();
            this.$message({
              type: "success",
              message: "提交成功",
              showClose: true,
            });
            this.form = {};
            this.visible = false;
          } catch {
            this.confirmLoading = false;
          }
        }
      });
    },
    tablePaginationChange(val) {
      if (this.search.size !== val.pageSize) {
        this.search.current = 1;
        this.search.size = val.pageSize;
      } else {
        this.search.current = val.current;
      }
    },
    async addUser(record) {
      const res = await scanPointUserList({
        scanPointId: record.id,
        level: record.level,
      });
      this.scanUserList = res.data.data;
      this.form = { ...record };
      let arr = (record.userIds && record.userIds.split(",")) || [];
      this.checkUserList = arr.map(Number);
      this.visible = true;
      this.title = record.userIds ? "编辑管理员" : "添加管理员";
      this.transferFilterKey = Math.random();
    },
    addScan() {
      this.visible = true;
      this.title = "添加扫描点";
      this.form = {};
      this.transferFilterKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
.scan {
  color: #0a1119;
  .operate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .title {
      color: #0a1119;
      font-size: 16px;
      font-weight: 600;
      .choose {
        margin-left: 18px;
        font-size: 14px;
        color: #0a1119;
        .num {
          color: #2474ed;
        }
      }
    }
  }
}

.shcools {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  .tag {
    box-sizing: border-box;
    padding: 8px 10px;
    background: #f5f7fa;
    border-radius: 4px;
    color: #3e4551;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 23%;
    margin-right: 2%;
    margin-bottom: 8px;
  }

  .user-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #ffffff;
    color: #0a1119;
    font-size: 14px;
    border: 1px solid #dee0e7;
    height: 34px;
    margin-right: 2%;
    margin-bottom: 8px;
    box-sizing: border-box;
    .name {
      width: 75px;
      box-sizing: border-box;
      padding: 0 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-right: 1px solid #dee0e7;
      border-radius: 4px;
      font-size: 12px;
      height: 32px;
      background-color: #f5f7fa;
      line-height: 32px;
    }
    .username {
      width: 60px;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 8px;
      font-size: 12px;
      line-height: 32px;
      white-space: nowrap;
      height: 32px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &:nth-child(4n) {
    margin-right: 0;
  }
}
.upload {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .uploadTips {
    background: #fff5f0;
    border: 1px dashed #ffb692;
    box-sizing: border-box;
    padding: 10px;
    flex: 1;
    color: #343231;
    font-size: 12px;
    margin-left: 18px;
    .footnote {
      color: #e5611f;
      margin-bottom: 18px;
    }
    .text {
      margin-bottom: 18px;
    }
  }
}

.text {
  margin-bottom: 18px;
  span {
    color: #f56c6b;
  }
}
.tips {
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  background-color: #f3f8ff;
  margin-bottom: 18px;
  line-height: 2;
}
.addBtn {
  width: 36px;
  height: 34px;
  line-height: 34px;
  background: #f2f6ff;
  color: #2474ed;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 2%;
  margin-bottom: 8px;
}
::v-deep .ant-tabs-tab {
  color: #0a1119 !important;
}
::v-deep .ant-modal-body {
  line-height: 1 !important;
}
</style>
