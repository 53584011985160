<template>
  <div class="transferFilter">
    <div class="item" style="margin-right: 24px">
      <div class="left-header">
        <a-button type="link" @click="allClick">全选</a-button>
        <a-button type="link" @click="reverse">反选</a-button>
      </div>
      <div class="content">
        <div class="searchInput">
          <a-input
            v-model="leftInput"
            placeholder="可输入学校名称搜索"
            @input="searchSchool"
          >
            <a-icon
              slot="suffix"
              type="search"
              style="color: rgba(0, 0, 0, 0.45)"
            />
          </a-input>
        </div>
        <div class="list">
          <template v-if="schoolList.length">
            <div
              v-for="(item, index) in schoolList"
              :key="index"
              class="list-item"
            >
              <a-checkbox
                v-model="item.checked"
                @change="(val) => checkChange(item, val)"
                >{{ item.name }}
              </a-checkbox>
            </div>
          </template>
          <no-data v-else></no-data>
        </div>
      </div>
    </div>
    <div class="item" style="margin-right: 24px">
      <div class="right-header">
        <div class="length">
          已选择<span>{{ checkedList.length }}</span
          >所学校
        </div>
        <div style="color: #f57574; cursor: pointer" @click="clearAll">
          <img src="@/assets/uExam/clear.png" style="width: 12px" alt="" />
          清空
        </div>
      </div>
      <div class="content">
        <div class="searchInput">
          <a-input v-model="rightInput" placeholder="可输入学校名称搜索">
            <a-icon
              slot="suffix"
              type="search"
              style="color: rgba(0, 0, 0, 0.45)"
            />
          </a-input>
        </div>
        <div class="list">
          <template v-if="checkedList.length">
            <div
              v-for="(item, index) in checkedList"
              :key="index"
              class="list-checked"
            >
              <div>{{ item.name }}</div>
              <div class="close" @click="handleDelete(item)">
                <a-icon type="close" />
              </div>
            </div>
          </template>
          <no-data v-else></no-data>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { scanPointSchoolList } from "@/core/api/newOther/union";
export default {
  name: "",
  props: {
    schoolIds: {
      type: Array,
      default: () => [],
    },
    level: {
      type: [String, Number],
      default: "",
    },
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      leftInput: undefined,
      rightInput: undefined,
      schoolList: [],
      checkSchoolList: [],
      checkList: [],
      old: [],
      oldCheckList: [],
    };
  },
  computed: {
    checkedList() {
      let checkedArr = this.old.filter((item) => item.checked);
      let arr = [];
      if (this.rightInput) {
        arr =
          checkedArr.filter(
            (item) => item.name.indexOf(this.rightInput) !== -1
          ) || [];
      } else {
        arr = [...checkedArr];
      }

      return arr;
    },
  },
  created() {
    this.getSchoolListData();
  },
  mounted() {},
  methods: {
    clearAll() {
      this.old.forEach((item) => {
        this.$set(item, "checked", false);
      });
      this.emitChange();
    },
    emitChange() {
      let checkeds = this.old.filter((item) => item.checked);
      this.$emit("change", checkeds);
    },
    handleDelete(item) {
      this.old.forEach((it) => {
        if (it.schoolId === item.schoolId) {
          this.$set(it, "checked", false);
        }
      });
      this.emitChange();
    },
    async getSchoolListData() {
      const res = await scanPointSchoolList({
        scanPointId: this.id,
        level: this.level,
      });
      this.schoolList = res.data.data;
      this.old = res.data.data;
      if (this.schoolIds && this.schoolIds.length) {
        this.old.forEach((item) => {
          if (this.schoolIds.includes(item.schoolId)) {
            this.$set(item, "checked", true);
          }
        });
      }
    },
    searchSchool() {
      if (this.leftInput) {
        this.schoolList = this.old.filter((item) => {
          if (item.name) {
            return item.name.indexOf(this.leftInput) !== -1;
          }
        });
      } else {
        this.schoolList = [...this.old];
      }
    },
    checkChange(item, val) {
      this.old.forEach((it) => {
        if (it.schoolId === item.schoolId) {
          this.$set(it, "checked", val.target.checked);
        }
      });
      this.emitChange();
    },
    allClick() {
      this.checkList = this.schoolList.forEach((item) => {
        this.$set(item, "checked", true);
      });
      this.emitChange();
    },
    reverse() {
      this.checkList = this.schoolList.forEach((item) => {
        this.$set(item, "checked", !item.checked);
      });
      this.emitChange();
    },
  },
};
</script>
<style lang="scss" scoped>
.transferFilter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  .item {
    border: 1px solid #dee0e7;
    flex: 1;
    .left-header {
      background-color: #f5f7fa;
      border-bottom: 1px solid #dee0e7;
      box-sizing: border-box;
      padding: 0px 18px;
      height: 40px;
    }
    .right-header {
      background-color: #f5f7fa;
      border-bottom: 1px solid #dee0e7;
      box-sizing: border-box;
      padding: 0px 18px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .length {
        span {
          color: #2474ed;
        }
      }
      .clear {
        color: #f57574;
        cursor: pointer;
      }
    }
    .content {
      box-sizing: border-box;
      padding: 18px;
      .searchInput {
        margin-bottom: 18px;
      }
      .list {
        background-color: #fff;
        height: 300px;
        overflow-y: auto;
        .list-item {
          display: flex;
          align-items: center;
        }
        .list-checked {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .close {
            cursor: pointer;
            &:hover {
              color: #2474ed;
            }
          }
        }
      }
    }
  }
}
</style>
