<template>
  <div class="addUpload">
    <div v-if="tips !== ''" class="tips">
      <div class="tips-title">【提示】</div>
      <div>{{ tips }}</div>
    </div>
    <slot name="tips"></slot>
    <div class="operate">
      <slot name="operate"> </slot>
      <slot v-if="fileModel" name="download">
        <div style="margin-left: 18px" @click="downLoadTemplate">
          <img src="@/assets/uExam/icon-down.png" alt="" />下载模板：<a-button
            type="link"
            >{{ fileModel.name }}</a-button
          >
        </div>
      </slot>
    </div>
    <div class="dragger-upload">
      <a-upload-dragger
        :file-list="fileList"
        name="file"
        :multiple="multiple"
        :headers="uploadAvatarHeaders"
        :accept="accept"
        open-file-dialog-on-click
        :custom-request="uploadRequest"
        :remove="uploadRemove"
        :disabled="disabled"
        @change="handleChange"
        @reject="rejectChange"
      >
        <p class="ant-upload-drag-icon">
          <img src="@/assets/uExam/el_down.png" alt="" />
        </p>
        <p class="ant-upload-text">点击或将文件拖拽到这里上传</p>
        <p>支持扩展名:{{ accept }}</p>
      </a-upload-dragger>
    </div>
  </div>
</template>

<script>
import { getStore } from "@/core/util/store";
export default {
  name: "AddUpload",
  props: {
    tips: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: [Number, null],
      default: null,
    },
    fileModel: {
      type: [Object, null],
      default: null,
    },
    actionUrl: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    accept: {
      type: String,
      default: ".xls,.xlsx",
    },
  },
  data() {
    return {
      uploadAvatarHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      fileList: [],
      baseUrl: process.env.VUE_APP_WEB_URL,
    };
  },
  created() {
    this.fileList = [];
  },
  methods: {
    uploadRemove() {
      this.fileList = [];
      this.$forceUpdate();
    },
    downLoadTemplate() {
      const { href } = this.$router.resolve({
        path: "/downFile",
        query: {
          url: this.fileModel.url,
          name: this.fileModel.name,
          type: 1,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    handleChange(event) {
      if (event.fileList.length > 0) {
        this.fileList = [event.file];
      }
      this.$emit("change", { ...event });
    },
    uploadRequest(options) {
      if (this.maxSize) {
        const { file } = options;
        if (file.size > this.maxSize) {
          this.$message({
            showClose: true,
            message: `文件大小不能大于${this.maxSize / 1024}M`,
            type: "warning",
          });
          return false;
        }
      }
      let progress = { percent: 1 };
      const intervalId = setInterval(() => {
        if (progress.percent < 100) {
          progress.percent++;
          options.onProgress(progress);
        } else {
          clearInterval(intervalId);
          options.onSuccess();
        }
      }, 2);
    },
    rejectChange() {
      this.$message.warning(`文件类型错误，仅支持${this.accept}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.addUpload {
  .tips {
    box-sizing: border-box;
    padding: 10px;
    color: #262829;
    font-size: 12px;
    margin-bottom: 18px;
    background-color: #f3f8ff;
    line-height: 2;
    .tips-title {
      color: #2474ed;
    }
  }
  .operate {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
  }
}
::v-deep .ant-btn-link {
  padding: 0 !important;
  span {
    text-decoration: underline;
  }
}
</style>
